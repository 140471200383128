import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			name: 'statistics',
			component: () => import('@/views/Statistics.vue'),
			meta: {
				resource: 'Admin',
				pageTitle: 'Статистика',
				breadcrumb: [
					{
						text: 'Статистика',
						active: true,
					},
				],
			},
		},
		{
			path: '/queries',
			name: 'queries',
			component: () => import('@/views/queries/query-list/QueryList.vue'),
			meta: {
				resource: 'Admin',
				pageTitle: 'Запросы',
				breadcrumb: [
					{
						text: 'Запросы',
						active: true,
					},
				],
			},
		},
		{
			path: '/users',
			name: 'users',
			component: () => import('@/views/user/users-list/UsersList.vue'),
			meta: {
				resource: 'Admin',
				pageTitle: 'Пользователи',
				breadcrumb: [
					{
						text: 'Пользователи',
						active: true,
					},
				],
			},
		},
		{
			path: '/oneton',
			name: 'oneton',
			component: () => import('@/views/1-to-n.vue'),
			meta: {
				resource: 'Admin',
				pageTitle: '1 to N',
				breadcrumb: [
					{
						text: '1 to N',
						active: true,
					},
				],
			},
		},
		{
			path: '/db-oneton',
			name: 'db-oneton',
			component: () => import('@/views/DbOneToN.vue'),
			meta: {
				resource: 'Admin',
				pageTitle: 'База 1:N',
				breadcrumb: [
					{
						text: 'База 1:N',
						active: true,
					},
				],
			},
		},
		{
			path: '/settings',
			name: 'settings',
			component: () => import('@/views/Settings.vue'),
			meta: {
				resource: 'Admin',
				pageTitle: 'Настройки',
				breadcrumb: [
					{
						text: 'Настройки',
						active: true,
					},
				],
			},
		},
		{
			path: '/demo',
			name: 'demo',
			component: () => import('@/views/Demo.vue'),
			meta: {
				resource: 'Admin',
				pageTitle: 'Демо',
				breadcrumb: [
					{
						text: 'Демо',
						active: true,
					},
				],
			},
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/pages/authentication/Login.vue'),
			meta: {
				resource: 'Auth',
				redirectIfLoggedIn: true,
				layout: 'full',
			},
		},
		{
			path: '/error-404',
			name: 'error-404',
			component: () => import('@/views/error/Error404.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '*',
			redirect: 'error-404',
		},
	],
})

router.beforeEach((to, _, next) => {
	const isLoggedIn = isUserLoggedIn()

	if (!canNavigate(to)) {
		// Redirect to login if not logged in
		if (!isLoggedIn) return next({ name: 'login' })

		// If logged in => not authorized
		return next({ name: 'error-404' })
	}

	// Redirect if logged in
	if (to.meta.redirectIfLoggedIn && isLoggedIn) {
		const userData = getUserData()
		next('/')
		// next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
	}

	return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})

export default router
